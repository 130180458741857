import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface AdObject {
  id: number;
  titleEn: string;
  titleAr: string;
}
export interface AdsListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  ads: Array<AdObject>;
  errors: unknown;
}

export interface PackagesObject {
  id: number;
  title: string;
}

export interface AdsFilterInfo {
  status: string;
  phoneNumber: number;
  isPaid: number;
  price: number;
  size: number;
  packageId: string;
  propertyCategoryId: number;
  propertyTypeId: number;
  areaId: number;
  adTypeId: number;
  memberId: number;
}

@Module
export default class AdsModule extends VuexModule implements AdsListInfo {
  ads = [] as Array<AdObject>;
  adsFilter = {} as AdsFilterInfo;
  packages = [] as Array<PackagesObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get ads list
   * @returns Array<AdObject>
   */
  get adsList(): Array<AdObject> {
    this.ads[0]['page'] = this.currentPage;
    return this.ads;
  }

  /**
   * Get total items
   * @returns number
   */
  get adsListCount(): number {
    return this.totalItems;
  }

  /**
   * Get total items
   * @returns number
   */
  get adsListPage(): number {
    return this.currentPage;
  }

  /**
   * Get ads packages list
   * @returns Array<PackagesObject>
   */
  get adsPackagesList(): Array<PackagesObject> {
    return this.packages;
  }

  @Mutation
  [Mutations.SET_ADS](data) {
    this.ads = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ADS_PACKAGES](data) {
    this.packages = data.data;
  }

  @Mutation
  [Mutations.SET_AD_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.SET_AD_FILTER](obj) {
    this.adsFilter.status = String(obj.status);
    this.adsFilter.phoneNumber = obj.phone != '965' ? Number(obj.phone) : 0;
    this.adsFilter.isPaid = Number(obj.isPaid);
    this.adsFilter.size = Number(obj.size);
    this.adsFilter.price = Number(obj.price);
    this.adsFilter.packageId = String(obj.packageId);
    this.adsFilter.propertyCategoryId = Number(obj.propertyCategoryId);
    this.adsFilter.propertyTypeId = Number(obj.propertyTypeId);
    this.adsFilter.areaId = Number(obj.areaId);
    this.adsFilter.adTypeId = Number(obj.adTypeId);

    this.adsFilter.memberId = Number(obj.memberId);
  }

  @Mutation
  [Mutations.RESET_ADS_LIST]() {
    this.ads = [] as Array<AdObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_ADS_STORE]() {
    this.context.commit(Mutations.RESET_ADS_LIST);
  }

  @Action
  [Actions.CREATE_AD](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ads', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_ADS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_AD](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/ads', id)
      .then(() => {
        this.context.commit(Mutations.RESET_ADS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_AD]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ads', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_ADS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_AD_STATUS]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/ads/update-status', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_ADS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.BULK_APPROVE_ADS](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ads/bulk-approvals', data)
      .then(() => {
        return true;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.APPROVE_ALL_ADS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/ads/bulk-all-approvals', {})
      .then(() => {
        return true;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_AD_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_AD_CURRENT_PAGE, val);
  }

  @Action
  [Actions.UPDATE_AD_FILTER](val) {
    this.context.commit(Mutations.SET_AD_FILTER, val);
  }

  @Action
  [Actions.GET_AD](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ads', id);
  }

  @Action
  [Actions.GET_AD_USER_INFO](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ads/user-info', id);
  }

  @Action
  [Actions.GET_ADS](options: { limit?: number } = { limit: 10 }) {
    ApiService.setAuthorizationHeader();
    const params = {
      page: this.currentPage,
      limit: options.limit,
      phoneNumber: this.adsFilter.phoneNumber,
      price: this.adsFilter.price,
      size: this.adsFilter.size,
      packageId: this.adsFilter.packageId
        ? Number(this.adsFilter.packageId)
        : 0,
      status: this.adsFilter.status ? Number(this.adsFilter.status) : -2,
      isPaid: this.adsFilter.isPaid,
      propertyCategoryId: this.adsFilter.propertyCategoryId
        ? Number(this.adsFilter.propertyCategoryId)
        : 0,
      propertyTypeId: this.adsFilter.propertyTypeId
        ? Number(this.adsFilter.propertyTypeId)
        : 0,
      areaId: this.adsFilter.areaId ? Number(this.adsFilter.areaId) : 0,
      adTypeId: this.adsFilter.adTypeId ? Number(this.adsFilter.adTypeId) : 0,
      memberId: this.adsFilter.memberId ? Number(this.adsFilter.memberId) : 0,
    };

    return ApiService.query('/ads', { params })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          r.createdAt = new Date(r.createdAt)
            .toLocaleString('en-GB')
            .split(',')[0];
          return r;
        });
        this.context.commit(Mutations.SET_ADS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.GET_ADS_PACKAGES_LIST]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/ads/ads-packages/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ADS_PACKAGES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }
  @Action
  [Actions.UPLOAD_ADS_IMAGES]({ data, adId }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(`/ads/uploadImages/${adId}`, data)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
  @Action
  [Actions.UPLOAD_ADS_VIDEO]({ data, adId }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(`/ads/uploadVideo/${adId}`, data)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPLOAD_ADS_DOCUMENT]({ data, adId }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(`/ads/uploadDocument/${adId}`, data)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
  @Action
  [Actions.DELETE_AD_DOCUMENT](id) {
    ApiService.setAuthorizationHeader();
    return (
      ApiService.delete('ads/deleteAttachments', id)
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .then(() => {})
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, {
            [response.statusText]: [response.data.message],
          });
        })
    );
  }
}
